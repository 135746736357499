.main-container {
    margin-top: 3.5em;
}
.main-footer {
    margin: 0.5em 0 3em 0;
    text-align: center;
}
.main-footer .main-footer .badge {
    padding: 0.5em 1.5em;
}
a.bold,
.navbar-dark .container a.active,
.navbar-dark .navbar-nav .active > .nav-link {
    font-weight: bold;
}

.main-container .bs-component .table td a {
    color: #495057;
}
.main-container .bs-component .table td.money {
    text-align: right;
}

/* .w-form .row .col-lg-12 .bs-component form button.btn-danger, */
.w-form .row .col-lg-12 .bs-component form button.btn-success {
    float: left;
    margin-right: 1em;
}
.main-container .bs-component p.adding a,
.row div div a.back,
.w-form .row .col-lg-12 a.back {
    display: block;
    margin: 1em 0;
    font-size: 1.2em;
    font-weight: bold;
    clear: both;
}

.row div div a.multi-back,
.w-form .row .col-lg-12 a.multi-back {
    display: inline-block;
    margin: 1em 1em 1em 0;
    padding-left: 1em;
    font-size: 1.2em;
    font-weight: bold;
    clear: left;
}

.w-form .row .col-lg-12 ul.nav-pills {
    float: left;
    margin: 0 1em 0 0;
}

.main-container p.bs-component button.disabled {
    font-weight: bold;
    color: #111;
}

.main-container .bs-component .semaine {
    float: left;
    margin: 0.25%;
    padding: 1em;
    width: 19%;
}

.main-container .bs-component .saison-1 {
    background-color: #0693ff;
    color: #000;
}
.main-container .bs-component .saison-2 {
    background-color: #03e6ff;
    color: #000;
}
.main-container .bs-component .saison-3 {
    background-color: #05ff97;
    color: #000;
}
.main-container .bs-component .saison-4 {
    background-color: #37ff00;
    color: #000;
}
.main-container .bs-component .saison-5 {
    background-color: #ffea00;
    color: #000;
}
.main-container .bs-component .saison-6 {
    background-color: #ff8b06;
    color: #fff;
}
.main-container .bs-component .saison-7 {
    background-color: #ff5601;
    color: #fff;
}
.main-container .bs-component .saison-8,
.main-container .bs-component .saison-9,
.main-container .bs-component .saison-10,
.main-container .bs-component .saison-11 {
    background-color: #f00;
    color: #fff;
}
.main-container div div ul .cash {
    background-color: #88c149;
    background-image: linear-gradient(#88c149, #73A839 60%, #699934);
    margin-right: 1em;
}

.main-container div div ul .contrat {
    /* background-color: #88c149;
    background-image: linear-gradient(#88c149, #73A839 60%, #699934); */
    margin-right: 1em; 
}

.modal-open .main-container .bs-component .modal .modal-dialog {
    max-width: 50%;
}
.main-container.w-form div.bs-component td .btn-link,
.main-container.w-form div.bs-component td button.btn.btn-link {
    padding: 0;
    line-height: inherit;
    color: #495057;
}

.main-container a {
    text-decoration: underline;
}

.main-container a:hover,
.main-container .nav-pills .nav-item a.nav-link {
    text-decoration: none;
}

.main-container .bs-component .btn-link {
    text-decoration: underline;
}

.main-container .bs-component .btn-link:focus,
.main-container .bs-component .btn-link:hover {
    text-decoration: none;
}

.main-container #table-messages,
.main-container #table-paiements,
.main-container #table-locations {
    display: none;
}

.main-container #table-messages.visible,
.main-container #table-paiements.visible,
.main-container #table-locations.visible {
    display: table;
}

.click-link {
    text-decoration: underline;
}

.click-link:focus,
.click-link:hover {
    text-decoration: none;
    cursor: pointer;
}

/* contrats */
body.contrat .main-container td.aright {
    text-align: right;
}

body.contrat .main-container tr.facture tr.solde td {
    padding-top: 1em;;
}

body.contrat .main-container table.garantie td {
    font-weight: bold;
    padding: 0.5em 0;
}

.main-container.w-form .col-lg-12 .bs-component .user-form form #user .form-group label.label-user {
    width: 33% !important;
}

.main-container.w-form .col-lg-12 .bs-component .user-form form #user .form-group input.input-user {
    width: 66% !important;
}

.main-container.home-container {
    padding-top: 1em;
}

.main-container.home-container .card {
    margin-bottom: 1em;
}

.main-container.home-container .card .card-body ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.main-container.home-container .card .card-body ul li {
    list-style: none;
}

.main-container.home-container .card .card-header h1 {
    font-size: 1.75em;
    margin: 0;
    padding: 0;
}

.main-container.w-form .bs-component .tab-content .tab-pane {
    padding-top: 1em;
}

.main-container.w-form .bs-component .nav-tabs .nav-item a.nav-link {
    text-decoration: none;
}

.main-container ul.nav-pills li.nav-item.pleft {
    padding-left: 1rem;;
}